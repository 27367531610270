@import "../../App.scss";

.container--experience {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.container--experience > :last-child {
  grid-column: 1 / -1;
}

.experience--frontend,
.experience--backend,
.experience--general {
  background: $bg-secondary;
  padding: 1.5rem 2rem 2.4rem 2.8rem;
  margin-bottom: 2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: $transtion-main;
  &:hover {
    background: transparent;
    border-color: $color-secondary;
    cursor: default;
  }
  & h3 {
    text-align: center;
    color: $color-primary;
    margin-bottom: 1.8rem;
  }
}
.experience--content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1.5rem;
  & .experience--details {
    display: flex;
    gap: 1rem;
    &-icon {
      margin-top: 6px;
      color: $color-primary;
    }
  }
}
/* ----- MEDIA QUERIES (MD) ----- */
@media screen and (max-width: 1024px) {
  .container--experience {
    grid-template-columns: 1fr;
    & .experience--frontend,
    .experience--general,
    .experience--backend {
      width: 80%;
      padding: 2rem;
      margin: 0 auto;
    }
    .experience--general {
      margin-top: 1rem;
    }
    .experience--content {
      padding: 1rem;
    }
  }
}
/* ----- MEDIA QUERIES (SM) ----- */
@media screen and (max-width: 600px) {
  .container--experience {
    gap: 1rem;
    .experience--content {
      padding: 0.1rem;
    }
    & .experience--frontend,
    .experience--general,
    .experience--backend {
      width: 95%;
      padding: 1.5rem 1rem;
    }
    .experience--general {
      margin-top: 1rem;
    }
  }
}
