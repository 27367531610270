@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

$white: #fff;
$body-bg: #212527;
$main-bg: #1f2123;
$bg-secondary: #2c2c6c;
$color-primary: #4db5ff;
$color-secondary: rgba(77, 181, 255, 0.4);
$color-shadow: rgba(255,255,255,0.6);
$transtion-main: all 400ms ease;

:root{
    --container-width-lg: 75%;
    --container-width-md: 87%;
    --container-width-sm: 90%;

    --color-primary: #4db5ff;
}

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
html{
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    display: none;
}
body{
    font-family: 'Poppins', sans-serif;
    background-color: $body-bg;
    color: $white;
    line-height: 1.8;
    background-image: url(../src/assets/bg-texture.png);
}

/*------ General styles -------- */ 
.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}
h1{
    font-size: 2.5rem;
}
.section-component{
    margin-top: 7rem;   
    h2{
        text-align: center;       
        color: $color-primary;
        margin-bottom: 2.5rem;
    }   
    h5 {
        text-align: center;
        color: $color-shadow;
    }
}
.text-shadow {
    color: $color-shadow
}
.text-primary{
    color: $color-primary
}
.text-secondary{
    color: $color-secondary
}
a{
    color: $color-primary;
    transition: $transtion-main;
}
a:hover{
    color: $white
}
.btn{
    width: max-content;
    display: inline-block;
    color: $color-primary;
    padding: 0.8rem 1.1rem;
    border-radius: 10px;
    border: 1px solid $color-secondary;
    transition: $transtion-main;
}

.btn:hover{
    background: $white;
    color: $main-bg;
    border-color: transparent
}
.btn-primary{
    color: $main-bg;
    background-color: $color-primary;
}
img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ----- MEDIA QUERIES (MD) ----- */
@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md)
    }
    .section-component{
        margin-top: 4.5rem;    
        h2{           
            margin-bottom: 2.1rem;
        }
    }
}
/* ----- MEDIA QUERIES (SM) ----- */
@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm)
    }
    .section-component{
        h2{           
            margin-bottom: 1.8rem;
        }       
    }
}