@import "../../App.scss";

.container--about {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}
.about--me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, $color-primary, transparent);
  display: grid;
  place-items: center;
  align-self: center;
}

.about--me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: $transtion-main;
  &:hover {
    transform: rotate(0deg);
  }
}
.about--content-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}
.about--content-card {
  background: $bg-secondary;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: $transtion-main;
  &:hover {
    background: transparent;
    border-color: $bg-secondary;
  }
}

.about--icon {
  color: $color-primary;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.about--content-card small {
  font-size: 0.8rem;
  color: $color-shadow;
}

.about--content p {
  margin: 2rem 0 2.6rem;
  color: $color-shadow;
}

/* ----- MEDIA QUERIES (MD) ----- */
@media screen and (max-width: 1024px) {
  .container--about {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .about--me {
    width: 50%;
    margin: 2rem auto 4rem;
  }
  .about--content {
    text-align: center;
    & p {
      margin: 1rem 0 1.5rem;
    }
  }
}
.about--article-caption {
  margin-top: 1rem;
}
/* ----- MEDIA QUERIES (SM) ----- */
@media screen and (max-width: 600px) {
  .about--me {
    width: 65%;
    margin: 0 auto 3rem;
  }
  .about--content-cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .about--content {
    text-align: center;
    & p {
      margin: 1.5rem 0;
    }
  }
}
