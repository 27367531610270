@import "../../App.scss";

.container--contact{
   width: 100%;
   display: grid;
   grid-template-columns: 30% 58%;
   gap: 12%;
}
.contact--options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact--option{
    background: $bg-secondary;
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: $transtion-main;
    &:hover{
        background-color: transparent;
        border-color: $color-secondary;
    }
    &-icon{
       font-size: 1.5rem; 
       margin-bottom: 0.5rem;
    }
    a{
        margin-top: 0.7rem;
        display: inline-block;
        font-size: 0.85rem;
    }
}
.contact--form{
    form {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
    input, textarea{
        width: 100%;
        padding: 1.5rem;
        border-radius: 0.5rem;
        background: transparent;
        border: 2px solid $color-secondary;
        resize: none;
        color: $white;
    }
    button{
        width: 100%;
        cursor: pointer;
        font-weight: 600;
        color: $white;
    }
}


/* ----- MEDIA QUERIES (MD) ----- */
@media screen and (max-width: 1024px) {
    .container--contact{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    
}
/* ----- MEDIA QUERIES (SM) ----- */
@media screen and (max-width: 600px) {    
    .container--contact{
       width: var(--container-width-sm);
    }
}