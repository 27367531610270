@import "../../App.scss";

header{
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
    h5{
        letter-spacing: 5px;
        text-transform: uppercase;
    }
}
.container--header{
    text-align: center;
    height: 100%;
    position: relative;
}
.cta {
    margin-top: 2.4rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}
.header--socials {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    left: 0;
    bottom: 5rem;
}
.header--socials::after{
    content: '';
    width: 2px;
    height: 2rem;
    background-color: $color-primary;
}
.header--me{
    background: linear-gradient($color-primary, transparent);
    width: 22rem;
    height: 27rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}
.scroll-down{
    position: fixed;
    right: 3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    z-index: 20;
}
.header--offerBlock {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header--offerBlock  a {
    position: relative;
    width: 170px;
    height: 60px;
    display: inline-block;
    margin: 15px;
}
.header--offerBlock a:before,
.header--offerBlock a:after {
    content: "";
    position: absolute;
    inset: 0;
    transition: 0.5s;
}
.header--offerBlock a:nth-child(1):before,
.header--offerBlock a:nth-child(1):after {
    background: linear-gradient(45deg, #00e69e, $color-primary, $bg-secondary, #00e69e );
}
.header--offerBlock a:nth-child(2):before,
.header--offerBlock a:nth-child(2):after {
    background: linear-gradient(45deg, $bg-secondary, #00e69e, #00e69e, $color-primary);
}
.header--offerBlock a:hover:before {
    inset: -3px;
}
.header--offerBlock a:hover:after {
    inset: -3px;
    filter: blur(10px);
}
.header--offerBlock a span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1a1a1a;
    z-index: 10;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    border: 2px solid #17181e;
    overflow: hidden;
}
.header--offerBlock a span:before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.075);
    transform: skew(25deg);
}
.header--offerBlock {
    width: 100%;
    min-height: 74px;    
    color: #fff;
    text-align: center;
}

/* ----- MEDIA QUERIES (MD) ----- */
@media screen and (max-width: 1024px) {
    header{
        height: 100vh;
    }
}
/* ----- MEDIA QUERIES (SM) ----- */
@media screen and (max-width: 600px) {
    header{
        height: 100vh; 
        h1 {
            font-size: 1.5rem;
        } 
        margin-top: 3rem!important;
    }
    .header--socials, .scroll-down{
        display: none;
    }
    .header--offerBlock  a {
        margin: 10px 5px;
        & span {
            font-size: 0.95rem;
        }
    }
    
}