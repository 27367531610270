@import "../../App.scss";

.container--services{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}
.service{
    background: $bg-secondary;
    border-radius: 0 0 2rem 2rem;
    border: 1px solid $color-primary;
   
    transition: $transtion-main;
    &:hover{
        background: transparent;
        border-color: $color-secondary;
        cursor: default;
    }
    &--link{
        text-align: center;
        margin-bottom: 1rem;
    }
}
.service--head{
    background: $color-primary;
    padding: 1.2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0,0,0,0.1);
    h3 {
        color: $main-bg;
        font-size: 1rem;
        text-align: center;
    }
}
.service--list{
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
    li {
        display: flex;
        gap: 1rem;
        margin-bottom: 0.8rem;
    }
    &-icon{
        color: $color-primary;
        margin-top: 4px;
        width: 10%;
    }
    p{
        font-size: 0.9rem
    }
}
@media screen and (max-width: 1024px) {
    .container--services{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .service {
        height: auto;
    }
}
/* ----- MEDIA QUERIES (SM) ----- */
@media screen and (max-width: 600px) {    
    .container--services{
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}